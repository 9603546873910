import React from "react";
import "./Footer.scss";
const Footer = () => {
  return (
    <div className="footer">
      <p className="footer__text">@2024 burgundythedev</p>
    </div>
  );
};

export default Footer;
